/* eslint-disable react/no-unescaped-entities */
import React, { useEffect } from 'react';
import { format } from 'date-fns';
import { observer } from 'mobx-react';
import { Link } from 'react-router-dom';
import Progress from 'Assets/svg/in-progress.svg';
import EmptyState from 'Assets/png/purchase-list-empty.png';
import usePagination from 'Components/common/usePagination';
import Pagination from 'Components/common/pagination';
import LoadingWrapper from 'Components/common/base/Loader';
import Title from 'Components/common/base/Title';
import purchaseListState from 'Stores/purchaseList';
import Utils from 'Utils/utils';
import { PAGE_PATH_MAP } from 'Utils/constants';
import './styles.scss';

const newStatus = {
  IN_PROGRESS: 'In Progress',
  COMPLETE: 'Complete',
  FAILED: 'Failed',
  IN_QUEUE: 'In Queue',
};

const statusMapping = {
  completed: newStatus.COMPLETE,
  failed: newStatus.FAILED,
  in_progress: newStatus.IN_PROGRESS,
  validation_requested: newStatus.IN_PROGRESS,
  validation_processed: newStatus.IN_PROGRESS,
  picked: newStatus.IN_PROGRESS,
  to_be_processed: newStatus.IN_QUEUE,
  skipped_verification_processed: newStatus.IN_PROGRESS,
  completed_pending_partial_index: newStatus.IN_PROGRESS,
};

let timeout;

function filterQueuedOrProgress(list) {
  return (
    list?.filter((data) => {
      return data.status !== 'COMPLETED' && data.status !== 'FAILED';
    }) ?? []
  );
}

function PurchaseHistory() {
  const { pagination, changePage, changePageSize } = usePagination();

  const startListener = () => {
    timeout = setTimeout(async () => {
      const list = purchaseListState.purchaseListData?.list;
      const purchaseInProgressOrQueued = filterQueuedOrProgress(list);
      if (purchaseInProgressOrQueued.length > 0) {
        const newResponse = await purchaseListState.fetchPurchaseList(
          {
            ...pagination,
            purchaseIds: purchaseInProgressOrQueued
              .map((req) => {
                return req.id;
              })
              .join(','),
          },
          false,
        );
        const newList = newResponse.data?.list;
        const newValues = filterQueuedOrProgress(newList);
        if (newValues.length > 0) {
          startListener();
        }
        purchaseListState.setPurchaseLists(
          purchaseListState.purchaseListData?.list.map((data) => {
            const newData = newList.find((prop) => {
              return prop.id === data.id;
            });
            if (newData !== undefined) {
              return newData;
            }
            return data;
          }),
        );
      } else if (!purchaseListState.isLoading) {
        clearTimeout(timeout);
      }
    }, 30000);
  };

  useEffect(() => {
    purchaseListState.getPurchaseList(pagination.page, pagination.size);
  }, [pagination]);

  useEffect(() => {
    startListener();
    return () => {
      clearTimeout(timeout);
    };
  }, []);

  return (
    <div className="bulk-purchase-history">
      <LoadingWrapper position="fixed" show={purchaseListState.isLoading} />
      <Title title="Bulk Purchase History" />
      {purchaseListState.purchaseListData?.list?.length > 0 ? (
        <>
          <h2>Bulk Purchase History</h2>
          <p>
            This is an audit report of all bulk contact purchases made from your
            Adapt account in the last 3 months
          </p>
          <div className="purchase-list-table">
            <div className="purchase-list-header">
              <span>Date</span>
              <span className="center">Contacts Requested</span>
              <span className="center">Contacts Purchased</span>
              <span className="center">Status</span>
            </div>
            <div className="purchase-list-body">
              <div className="purchase-list-body-inner">
                {purchaseListState.purchaseListData.list.map((purchaseData) => {
                  const {
                    id,
                    createdDate,
                    purchasedCount,
                    requestedCount,
                    status,
                  } = purchaseData;

                  const statusValue = statusMapping[status.toLowerCase()];

                  return (
                    <div className="purchase-data-row" key={id}>
                      <div>
                        {format(createdDate, 'MMM dd, yyyy')} |{' '}
                        {format(createdDate, 'H:mm a')}
                      </div>
                      <div className="center">
                        {Utils.numberFormat(requestedCount)}
                      </div>
                      <div className="center">
                        {statusValue === newStatus.IN_QUEUE ||
                        statusValue === newStatus.IN_PROGRESS ? (
                          <Progress />
                        ) : purchasedCount > 0 ? (
                          Utils.numberFormat(purchasedCount)
                        ) : (
                          '-'
                        )}
                      </div>
                      <div
                        className={`status center ${statusValue?.toLowerCase()?.replace(' ', '-')}`}
                      >
                        {statusValue}
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
          {purchaseListState.purchaseListData.count > 15 && (
            <Pagination
              totalCount={purchaseListState.purchaseListData.count}
              updateCurrentPage={changePage}
              onRowsPerPageUpdate={changePageSize}
              currentPage={pagination.page}
              rowsPerPage={pagination.size}
              totalText="Total Available"
            />
          )}
        </>
      ) : (
        !purchaseListState.isLoading && (
          <div className="no-history-wrapper">
            <img src={EmptyState} alt="empty state" />
            <div className="no-history-title">
              This is an audit report of all bulk contact purchases made from
              your Adapt account in the last 3 months
            </div>
            <p className="no-history-message">
              It seems you haven't performed any bulk purchases recently
            </p>
            <p className="no-history-message">
              Purchase contacts in bulk from{' '}
              <Link to={PAGE_PATH_MAP.CONTACT_SEARCH}>contact search</Link> and
              we will show you the status of those purchases here
            </p>
          </div>
        )
      )}
    </div>
  );
}

export default observer(PurchaseHistory);
